<template>
  <WidgetFrame @onResize="onResize">
    <template v-slot:title>
      {{ $t('permission.staff_utilization') }}
    </template>
    <template v-slot:content>
      <b-form-group class="ml-3">
        <b-form-checkbox class="d-inline-flex stf-util-setting-checkbox" size="lg" v-model="usageBookings" @change="buildData">
          {{ $t('booking.title') }}
        </b-form-checkbox>
        
        <b-form-checkbox class="d-inline-flex ml-3 stf-util-setting-checkbox" size="lg" v-model="usageActivities" @change="buildData">
          {{ $t('activity.title') }}
        </b-form-checkbox>
        
        <b-form-checkbox class="d-inline-flex ml-3 stf-util-setting-checkbox" size="lg" v-model="usageTasks" @change="buildData">
          {{ $t('task.title') }}
        </b-form-checkbox>
      </b-form-group>
      
      <template v-if="!canView('TASK')">
        <div class="center-text">
          {{ $t('entity_selector.error.insufficient_permission_to_show_data') }}
        </div>
      </template>
      <template v-else-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else-if="noUsages">
        <div class='status-message'>No staff utilization</div>
      </template>
      <template v-else>
        <ag-charts-vue v-if="loaded" :options="options"></ag-charts-vue>
      </template>
    </template>
  </WidgetFrame>
</template>

<script>
import * as moment from 'moment-timezone';
moment.tz.setDefault('Etc/UTC');
import { AgChartsVue } from 'ag-charts-vue';
import { formatDate } from '@/helpers';
import { staffService } from '@/services';
import cColors from "@/_chartColors";
import { EventBus, calculateStaffUsage, processSystemCalendar } from '@/helpers';
import { tooltipRenderer } from '@/helpers/ag-chart-tooltip-renderer';
import { DEFAULT_CALENDAR
} from '@/helpers/task-duration-process';

export default {
  name: 'HomeStaffUtilizationWidget',
  components: {
    WidgetFrame: () => import('@/components/Dashboard/WidgetFrame'),
    AgChartsVue,
  },
  data: function() {
    return {
      width: 0,
      height: 0,
      loaded: false,
      options: null,
      noUsages: false,

      data: [], // for counting working days
      staffData: [], // for counting number of staff
      usages: null,
      maxStaffVal: 0,
      maxVal: 0,
      usageTasks: true,
      usageBookings: true,
      usageActivities: true
    }
  },
  created() {
    EventBus.$on('theme-change', () => {
      if (this.loaded) {
        this.buildChart();
      }
    });
    
    if (this.canView('TASK')) {
      this.buildData();
    }
  },
  beforeDestroy() {
    EventBus.$off('theme-change');
  },
  methods: {
    async buildData() {
      this.loaded = false;
      this.data = [];
      if (this.canView('STAFF')) {
        await this.getStaffUsage();
      }
      
      if (!this.usages || this.usages.length == 0) {
        this.noUsages = true;
      } else {
        this.processUsage();
        this.buildChart();
      }
      
      this.loaded = true;
    },
    buildChart() {   
      // Convert the months to text names, put staff count in
      let monthNameFmt = this.width < 550 ? 'MMM' : 'MMMM';
      for (var i = 0; i < this.data.length; i++) {
        if (this.data[i]) {
          this.data[i].monthName = moment().month(this.data[i].month).format(monthNameFmt);
          this.data[i].staff = this.staffData[i].staff;
        }
      }

      this.options = {
        data: this.data,
        background: {
          fill: '#00000000', // Transparent in every theme
        },
        series: [
          {
            type: 'line',
            xKey: 'monthName',
            yKey: 'days',
            yName: this.$t('button.days'),
            marker: {
              enabled: true,
              size: 8,
              fill: cColors.getThemeColor('status-green'),
              stroke: "#22aa22"
            },
            stroke: cColors.getThemeColor('status-green'),

            tooltip: {
              enabled: true,
              renderer: tooltipRenderer
            }
          },
          {
            type: 'line',
            xKey: 'monthName',
            yKey: 'staff',
            yName: this.$t('location.field.headCount'),
            marker: {
              enabled: true,
              size: 8,
              fill: cColors.getThemeColor('status-red'),
              stroke: "#aa2222"
            },
            stroke: cColors.getThemeColor('status-red'),

            tooltip: {
              enabled: true,
              renderer: tooltipRenderer
            }
          },
        ],
        axes: [
          {
            type: 'category',
            position: 'bottom',
            title: { 
              text: this.$t('timescale.month'),
              color: cColors.getThemeColor('text-medium')
            },
            label: {
              rotation: 0,
              color: cColors.getThemeColor('text-medium'),
              fontFamily: 'Roboto'
            },
            gridStyle: [{ stroke: cColors.getThemeColor('axis-stroke'), lineDash: [0, 0] }]
          },
          {
            type: 'number',
            position: 'left',
            keys: ['days'],
            title: {
              enabled: true,
              text: this.$t('button.days'),
              color: cColors.getThemeColor('text-medium')
            },
            tick: { count: 8 },
            gridStyle: [{ stroke: cColors.getThemeColor('axis-stroke'), lineDash: [0, 0] }],
            label: {
              color: cColors.getThemeColor('text-medium'),
              fontFamily: 'Roboto'
            },
          },
          {
            type: 'number',
            position: 'right',
            keys: ['staff'],
            title: { 
              enabled: true,
              text: this.$t('staff.title')
            },
            tick: { count: 8 },
            gridStyle: [
              // Hide background for this axis, or they overlap and look bad
              {
                stroke: 'rgba(255, 0, 0, 0)',
                lineDash: [0, 0]
              }
            ],
            label: {
              color: cColors.getThemeColor('text-medium'),
              fontFamily: 'Roboto'
            },
          },
        ],
        legend: {
          enabled: true,
          position: 'top',
          item: { label: { color: cColors.getThemeColor('text-medium'), fontFamily: 'Roboto'}}
        },
      };
      if (this.width < 380) {
        this.options.axes[0].label.rotation = -37;
        this.options.padding = {left: 0, right: 0};
      }
    },
    processUsage() {
      const b = new Date();
      b.setMonth(b.getMonth() - 3);
      const u = new Date();
      u.setMonth(u.getMonth() + 3);
      this.data.length = 6;
      const iter = new Date(b);
      var order = [];
      while (iter <= u) {
        order.push(iter.getMonth());
        iter.setMonth(iter.getMonth() + 1);
      }

      var staffCount = [];
      for (var prop in this.usages) {
        for (var keyalloc of Object.keys(this.usages[prop].resourceAllocationList)) {
          const alloc = this.usages[prop].resourceAllocationList[keyalloc];
          const matches = keyalloc.match(/(.+?)-(.+?$)/);       
          const monthIndex = matches !== null ? parseInt(matches[2]) - 1 : 0; // zero based index
          const index = order.indexOf(monthIndex);
          if (typeof this.data[index] === 'undefined') {
            this.data[index] = { month: monthIndex, days: 0};
            this.staffData[index] = { month: monthIndex, days: 0};
          }
          if (typeof alloc.w !== 'undefined') {
            this.data[index].days += alloc.w/8; // Assuming an 8-hour workday, like backend
          }
          if (typeof alloc.w !== 'undefined' && alloc.w != 0) {
            if (typeof staffCount[index] === 'undefined') {
              staffCount[index] = {};
            }
            staffCount[index][prop] = true;
          }
          
          if (this.data[index].days > this.maxVal) {
            this.maxVal = this.data[index].days;
          }
        }
      }
      
      for (var index in staffCount) {
        this.staffData[index].staff = Object.keys(staffCount[index]).length;
        if (this.staffData[index].days > this.maxStaffVal) {
          this.maxStaffVal = this.staffData[index].days;
        }
      }
    },
    async getStaffUsage() {
      const self = this;
      const b = new Date();
      b.setMonth(b.getMonth() - 3);
      const u = new Date();
      u.setMonth(u.getMonth() + 3);
      var params = { begin: formatDate(b), until: formatDate(u), holder: this.$store.state.company.uuId };
      await staffService.usage(params, true).then(response => {
        // No usage data returns in a strange format
        if (response.data.jobCase) {
          const data = response.data[response.data.jobCase];  
          const entityList = response.data['entityList'];
        const baseCalendar = entityList['00000000-0000-0000-0000-000000000000'] ? processSystemCalendar(entityList['00000000-0000-0000-0000-000000000000'].calendarList) : DEFAULT_CALENDAR;
          
          for (let j = 0; j < data.length; j++) {
            // prepare calendar lists
            const locationUuId = data[j].locationList.length > 0 ? data[j].locationList[0].uuId : null;
            const locationCalendar = locationUuId !== null ? entityList[locationUuId].calendarList : null;
            const calendarList = data[j].calendarList;
            const calendars = [ calendarList, locationCalendar, baseCalendar ];
            // populate the tasks from the dictionary
            if (data[j].taskList) {
              for (const task of data[j].taskList) {
                for(var k in entityList[task.uuId]) task[k]=entityList[task.uuId][k];
              }
            }
            if (data[j].activityList) {
              for (const activity of data[j].activityList) {
                for(var m in entityList[activity.uuId]) activity[m]=entityList[activity.uuId][m];
              }
            }
            data[j].resourceAllocationList = calculateStaffUsage(data[j], moment(b), moment(u), 'month', calendars, { bookings: this.usageBookings, activities: this.usageActivities, tasks: this.usageTasks });
          }
          self.usages = data;
        } else {
          self.usages = [];
        }
      }).catch(e => {
        console.error(e);// eslint-disable-line no-console
      });
    },
    onResize({width, height}) {
      this.width = width;
      this.height = height;
      if (this.loaded) {
        this.buildChart();
      }
    }
  },
}
</script>
