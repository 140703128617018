var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("WidgetFrame", {
    on: { onResize: _vm.onResize },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm._v(" " + _vm._s(_vm.$t("permission.staff_utilization")) + " "),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "b-form-group",
              { staticClass: "ml-3" },
              [
                _c(
                  "b-form-checkbox",
                  {
                    staticClass: "d-inline-flex stf-util-setting-checkbox",
                    attrs: { size: "lg" },
                    on: { change: _vm.buildData },
                    model: {
                      value: _vm.usageBookings,
                      callback: function ($$v) {
                        _vm.usageBookings = $$v
                      },
                      expression: "usageBookings",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("booking.title")) + " ")]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    staticClass: "d-inline-flex ml-3 stf-util-setting-checkbox",
                    attrs: { size: "lg" },
                    on: { change: _vm.buildData },
                    model: {
                      value: _vm.usageActivities,
                      callback: function ($$v) {
                        _vm.usageActivities = $$v
                      },
                      expression: "usageActivities",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("activity.title")) + " ")]
                ),
                _c(
                  "b-form-checkbox",
                  {
                    staticClass: "d-inline-flex ml-3 stf-util-setting-checkbox",
                    attrs: { size: "lg" },
                    on: { change: _vm.buildData },
                    model: {
                      value: _vm.usageTasks,
                      callback: function ($$v) {
                        _vm.usageTasks = $$v
                      },
                      expression: "usageTasks",
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("task.title")) + " ")]
                ),
              ],
              1
            ),
            !_vm.canView("TASK")
              ? [
                  _c("div", { staticClass: "center-text" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "entity_selector.error.insufficient_permission_to_show_data"
                          )
                        ) +
                        " "
                    ),
                  ]),
                ]
              : !_vm.loaded
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              : _vm.noUsages
              ? [
                  _c("div", { staticClass: "status-message" }, [
                    _vm._v("No staff utilization"),
                  ]),
                ]
              : [
                  _vm.loaded
                    ? _c("ag-charts-vue", { attrs: { options: _vm.options } })
                    : _vm._e(),
                ],
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }