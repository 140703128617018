var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "md",
            title: _vm.title,
            "footer-class": "footerClass",
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "anti-shift",
            "ok-title": _vm.$t("button.select"),
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function (ref) {
                var cancel = ref.cancel
                return [
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "success" },
                        on: { click: _vm.ok },
                      },
                      [_vm._v(_vm._s(_vm.$t("button.ok")))]
                    ),
                  ],
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.modalShow,
            callback: function ($$v) {
              _vm.modalShow = $$v
            },
            expression: "modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: _vm.alertError ? "danger" : "success",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: {
                  icon: _vm.alertError
                    ? ["fas", "triangle-exclamation"]
                    : ["far", "check"],
                },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
            ],
            1
          ),
          _c("label", { staticClass: "mr-1" }, [
            _vm._v(_vm._s(_vm.$t("dataview.field.members"))),
          ]),
          _c(
            "button",
            {
              staticClass: "btn-action",
              attrs: { id: "profile_edit_member_add_btn" },
              on: { click: _vm.userSelectorToggle },
            },
            [_c("font-awesome-icon", { attrs: { icon: ["far", "plus"] } })],
            1
          ),
          _c(
            "b-popover",
            {
              attrs: {
                target: "profile_edit_member_add_btn",
                triggers: "hover",
                placement: "top",
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("task.group.add_profile_member_tooltip")) +
                  " "
              ),
            ]
          ),
          _c("BadgeGroup", {
            staticClass: "mb-3",
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var item = ref.item
                  var index = ref.index
                  return [
                    _c("Badge", {
                      key: index,
                      attrs: {
                        text: item.name,
                        variant: "white",
                        pillable: !!item.pillable,
                      },
                      on: {
                        badgeRemove: function ($event) {
                          return _vm.removeMember(index)
                        },
                        badgeClick: function ($event) {
                          return _vm.editMember(index)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.memberList,
              callback: function ($$v) {
                _vm.memberList = $$v
              },
              expression: "memberList",
            },
          }),
        ],
        1
      ),
      _vm.userSelectorShow
        ? _c("GenericSelectorModalForAdmin", {
            attrs: {
              show: _vm.userSelectorShow,
              entityService: _vm.userUtil,
              entity: "USER",
              nonAdmin: "",
            },
            on: {
              "update:show": function ($event) {
                _vm.userSelectorShow = $event
              },
              ok: _vm.userSelectorOk,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }